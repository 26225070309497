












import { defineComponent, ref } from 'vue-demi';
import CartTable from './CartTable.vue';
import { onClickOutside } from '@vueuse/core';

export default defineComponent({
  components: {
    CartTable,
  },
  setup() {
    const cartRef = ref<HTMLDivElement | null>(null);
    const showCart = ref(false);

    onClickOutside(cartRef, () => {
      showCart.value = false;
    });

    return {
      showCart,
      cartRef,
    };
  },
});
