import { errorCatcher } from '@/composables/errorCatcher';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Auth } from './Auth';
import { Certificates } from './Certificates';
import { Downloader } from './Downloader';
import { Parts } from './Parts';
import { Schematics } from './Schematics';
import { Users } from './Users';

export interface RequestConfig extends AxiosRequestConfig {
  disableErrorCatcher?: boolean;
}

export class Client {
  public http: AxiosInstance = axios.create({
    baseURL: '/api',
  });

  public auth: Auth = new Auth(this.http);
  public users: Users = new Users(this.http);
  public downloader: Downloader = new Downloader(this.http);
  public parts: Parts = new Parts(this.http);
  public certificates: Certificates = new Certificates(this.http);
  public schematics: Schematics = new Schematics(this.http);

  constructor() {
    this.http.interceptors.response.use(undefined, (err: AxiosError) => {
      const config = err.config as RequestConfig;
      if (!config.disableErrorCatcher) errorCatcher(err);
      return Promise.reject(err);
    });
  }
}
