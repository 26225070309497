


















import { defineComponent, onBeforeUnmount, computed } from 'vue-demi';
import { useOnline } from '@vueuse/core';
import { usePartsStore } from '../partsStore';
import { AxiosError } from 'axios';
import { useRouter } from '../../router';

export default defineComponent({
  setup() {
    const isOnline = useOnline();
    const parts = usePartsStore();
    const router = useRouter();

    async function checkUpdates() {
      return parts.getUpdates();
    }

    function onCheckError(err: AxiosError) {
      if (err.response && err.response.status === 401) {
        router.replace({
          name: 'login',
        });
      } else {
        console.error(err);
      }
    }

    const checkIntervalId = setInterval(() => {
      checkUpdates().catch(onCheckError);
    }, 30 * 1000);
    checkUpdates().catch(onCheckError);

    onBeforeUnmount(() => {
      clearInterval(checkIntervalId);
    });

    const updatesCount = computed(() => parts.updatesCount.value);

    return {
      isOnline,
      updatesCount,
    };
  },
});
