import { User } from '@project/shared';
import { useOnline } from '@vueuse/core';
import { watch } from 'vue-demi';
import { NavigationGuardNext, Route } from 'vue-router';
import { useApi } from './api';
import { kv } from './db/Kv';
import { hasDb } from './composables/hasDb';
import { useRouter } from './router';
import { useStore } from './store';
import { db } from './db/AppDb';

export async function bootstrap() {
  const api = useApi();
  const store = useStore();
  const isOnline = useOnline();
  const router = useRouter();

  await db.open();

  async function canNavigate(route: Route, next?: NavigationGuardNext) {
    route = route || router.currentRoute;

    const publicRoutes = ['login', 'offline'];
    const noDbRoutes = ['login', 'downloader', 'offline'];

    // Get current user
    let user = store.state.user || (store.state.user = await kv.get<User>('auth.user'));
    if (!user && isOnline.value) {
      try {
        user = await api.auth.me();
      } catch {
        // Silent fail
      }
    }

    // Not logged
    if (!user) {
      if (!publicRoutes.includes(route.name || '')) {
        if (next) {
          next({
            name: isOnline.value ? 'login' : 'offline',
          });
        }
        return false;
      } else {
        return true;
      }
    }

    // Check user has DB
    const dbAvailable = await hasDb();
    if (!dbAvailable && !noDbRoutes.includes(route.name || '')) {
      if (next) next({ name: isOnline.value ? 'downloader' : 'offline' });
      return false;
    }

    return true;
  }

  await canNavigate(router.currentRoute, router.replace.bind(router) as NavigationGuardNext);
  router.beforeEach(async (to, from, next) => {
    if (await canNavigate(to, next)) {
      next();
    }
  });

  watch(isOnline, async (value) => {
    if (!value && !(await canNavigate(router.currentRoute))) {
      router.replace({ name: 'offline' });
    }
  });
}
