import { User } from '@project/shared';
import { computed, inject, provide, reactive, watch } from '@vue/composition-api';
import { useStorage } from '@vueuse/core';

export interface StoreState {
  user?: User;
  showUsersDialog?: boolean;
  showSearchPart?: boolean | string;
}

export function createStore() {
  const storage = useStorage<StoreState>(
    'app.store',
    {
      user: undefined,
      showUsersDialog: false,
      showSearchPart: false,
    },
    window.localStorage,
  );
  storage.value.showUsersDialog = false;
  storage.value.showSearchPart = false;
  const state = reactive<StoreState>({
    ...storage.value,
  });

  const isLogged = computed(() => !!state.user);

  const store = {
    state,
    isLogged,

    hasRoles(roles: string[]) {
      if (state.user && state.user.roles) {
        for (const role of roles) {
          if (state.user.roles.includes(role)) {
            return true;
          }
        }
      }
      return false;
    },
  };

  provide('$store', store);

  watch(
    () => state,
    () => {
      storage.value = state;
    },
    {
      deep: true,
    },
  );

  return store;
}

export function useStore() {
  return inject('$store') as ReturnType<typeof createStore>;
}
