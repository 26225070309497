













import { defineComponent, computed, ref } from '@vue/composition-api';
import { usePartsStore } from '../partsStore';
import { useRouter, useRoute } from '@/router';
import { Part } from '@project/shared';
import { Route } from 'vue-router';

export default defineComponent({
  setup() {
    const router = useRouter();
    const route = useRoute();
    const parts = usePartsStore();
    const selectedCodeAmos = ref('');

    const items = computed(() => {
      return parts.seItems.value?.map((item) => {
        const GA = item.refManufacturer;
        return {
          text: `${GA} - ${item.codeAmos} - ${item.modelMetadata?.title}`,
          value: item,
        };
      });
    });

    function onChange(value: Part) {
      if (value) {
        router.push({
          name: 'parts',
          params: {
            codeAmos: value.codeAmos,
          },
        });
      } else {
        router.push('/');
      }
    }

    function onRouteChange(route: Route | null) {
      if (route) {
        selectedCodeAmos.value = route.params.codeAmos;
      } else {
        selectedCodeAmos.value = '';
      }
    }

    onRouteChange(route.value);
    router.afterEach((to) => {
      if (to.name === 'parts') {
        onRouteChange(to);
      } else {
        onRouteChange(null);
      }
    });

    const selected = computed(() => {
      return parts.seItems.value.find((item) => item.codeAmos === selectedCodeAmos.value);
    });

    return {
      items,
      onChange,
      selected,
    };
  },
});
