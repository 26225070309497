


















import { defineComponent, ref } from '@vue/composition-api';
import { provideToast } from 'vue-toastification/composition';
import { createStore } from './store';
import { bootstrap } from './bootstrap';
import LayoutManager from './components/LayoutManager.vue';
import UsersDialogs from './users/components/UsersDialogs.vue';
import SearchPart from './db/components/SearchPart.vue';
import { createCartStore } from './db/cartStore';
import { createPartsStore } from './db/partsStore';

export default defineComponent({
  components: {
    LayoutManager,
    UsersDialogs,
    SearchPart,
  },
  setup() {
    const loaded = ref(false);

    createStore();
    createPartsStore();
    createCartStore();
    provideToast({ timeout: 3000 });

    (async () => {
      await bootstrap();
      loaded.value = true;
    })().catch((err) => {
      console.error(err);
    });

    function refresh() {
      window.location.reload(true);
    }

    return {
      loaded,
      refresh,
    };
  },
});
