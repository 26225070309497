import { Part } from '@project/shared';
import { computed, inject, provide, reactive } from 'vue-demi';
import { kv } from './Kv';

export interface CartItem {
  codeAmos: string;
  quantity: number;
  data?: Part;
}

export interface CartState {
  items: CartItem[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

export function createCartStore() {
  const state = reactive<CartState>({
    items: [],
  });

  kv.get<CartState>('cart')
    .then((stored) => {
      state.items = stored?.items || [];
    })
    .catch(noop);

  const sortedItems = computed(() => {
    return state.items.sort((a, b) => a.codeAmos.localeCompare(b.codeAmos));
  });

  const store = {
    state,
    sortedItems,

    put(item: Part, quantity = 1) {
      const index = state.items.findIndex((row) => row.codeAmos === item.codeAmos);
      if (index >= 0) {
        const newQuantity = state.items[index].quantity + quantity;
        const updatedItem: CartItem = {
          ...state.items[index],
          quantity: newQuantity,
        };

        const replaceItems = newQuantity > 0 ? [updatedItem] : [];
        state.items.splice(index, 1, ...replaceItems);
      } else {
        state.items.push({
          codeAmos: item.codeAmos,
          quantity,
          data: item,
        });
      }

      kv.set('cart', state).catch(noop);
    },

    remove(codeAmos: string) {
      const index = state.items.findIndex((row) => row.codeAmos === codeAmos);
      if (index >= 0) {
        state.items.splice(index, 1);
        kv.set('cart', state).catch(noop);
      }
    },

    clear() {
      state.items = [];
      kv.set('cart', state).catch(noop);
    },

    export() {
      throw new Error('Not implemented');
    },

    persist() {
      return kv.set('cart', state).catch(noop);
    },

    isInCart(codeAmos: string) {
      const index = state.items.findIndex((row) => row.codeAmos === codeAmos);
      return index >= 0;
    },
  };

  provide('cartStore', store);

  return store;
}

export type CartStore = ReturnType<typeof createCartStore>;

export function useCartStore() {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return inject<CartStore>('cartStore')!;
}
