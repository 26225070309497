import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import VueCompositionAPI from '@vue/composition-api';
import vuetify from './plugins/vuetify';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import '@/components/HasRoles';

Vue.config.productionTip = false;

Vue.use(VueCompositionAPI);
Vue.use(Toast);

export const app = new Vue({
  router,
  vuetify,
  render: (h) => h(App),
});

router.onReady(() => {
  app.$mount('#app');
});
