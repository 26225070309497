import { Part } from '@project/shared';
import { db } from './AppDb';

export interface PartsFilters {
  parent?: string;
  noSubLevel?: boolean;
  noTop?: boolean;
}

class PartsDb {
  public items: Part[] = [];
  public isReady = false;

  public async load() {
    const rows = await db.parts.toArray();
    this.items = rows.map((item) => item.data as Part);
    this.isReady = true;
    return this.items;
  }

  public async search(search = '', filters: PartsFilters = {}) {
    if (!this.isReady) {
      await this.load();
    }

    let results = [...this.items];

    if (filters.parent) {
      const parent = await this.getByCodeAmos(filters.parent);
      if (parent) {
        let parentGa = parent.subLevelGa || parent.ga || '';
        if (!parent.ga && !parentGa) {
          parentGa = parent.refManufacturer || '';
        }

        results = results.filter((item) => {
          return item.gaItems && item.gaItems[parentGa] !== undefined;
        });
      }
    }

    if (filters.noTop) {
      results = results.filter((item) => {
        return !item.top;
      });
    }

    if (filters.noSubLevel) {
      results = results.filter((item) => {
        return !item.subLevelGa;
      });
    }

    search = search && search.toLowerCase().trim();
    if (search) {
      results = results.filter((item) => {
        return (
          item.codeAmos.toLowerCase().includes(search) ||
          item.description?.toLowerCase().includes(search)
        );
      });
    }

    return results;
  }

  public async getFile(id: string) {
    const row = await db.files.get(id);
    return row?.file;
  }

  public async getByMeshId(meshId: string) {
    const row = await db.parts.get({ 'data.modelMetadata.meshId': meshId });
    return row?.data;
  }

  public async getByCodeAmos(codeAmos: string) {
    if (!codeAmos) return;
    const row = await db.parts.get({ codeAmos });
    return row?.data;
  }

  public async getByRefManufacturer(ref: string) {
    if (!ref) return;
    const row = await db.parts.get({ 'data.refManufacturer': ref });
    return row?.data;
  }

  public async getPartsMeshes() {
    const rows = await db.parts
      // Query
      .where('data.modelMetadata.meshId')
      .notEqual('')
      .toArray();
    return rows.map((row) => row.data);
  }
}

export const partsDb = new PartsDb();
