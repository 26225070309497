



















import { defineComponent } from '@vue/composition-api';
import MainHeader from './MainHeader.vue';

export default defineComponent({
  components: { MainHeader },
  setup() {
    return {
      version: process.env.VUE_APP_TAG,
    };
  },
});
