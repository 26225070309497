






































import { defineComponent, reactive, ref, watch, toRaw, PropType } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
import { useApi } from '@/api';
import { UserCreateBody, UserUpdateBody } from '@project/shared';

export default defineComponent({
  props: {
    userId: {
      type: String,
      default: '',
    },
    value: {
      type: Object as PropType<UserCreateBody | UserUpdateBody>,
      required: true,
    },
  },

  setup(props, { emit }) {
    const toast = useToast();
    const api = useApi();
    const loading = ref(false);

    const formRef = ref<{
      validate(): void;
    } | null>(null);

    let values = reactive<UserCreateBody | UserUpdateBody>({
      email: '',
      password: '',
      roles: [],
    });

    const rules = {
      email: [(v: string) => !!v || 'Email is required'],
    };

    const validForm = ref(false);

    watch(
      () => props.value,
      (item) => {
        values = reactive({ ...item, password: '' });
      },
      {
        immediate: true,
      },
    );

    async function onSubmit() {
      if (validForm.value) {
        loading.value = true;
        try {
          const body = { ...toRaw(values) };
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          delete body._id;

          if (props.userId) {
            await api.users.update(props.userId, body);
          } else {
            await api.users.create(body as UserCreateBody);
          }
          // TODO: API call
          emit('input', toRaw(values));
        } finally {
          loading.value = false;
        }
      } else {
        toast.warning('Some fields are empty or incorrect values');
      }
    }

    const roles = ['admin', 'operator', 'member'];

    return {
      values,
      validForm,
      rules,
      formRef,
      onSubmit,
      loading,
      roles,
    };
  },
});
