






































import { defineComponent } from 'vue-demi';
import { useCartStore, CartItem } from '../cartStore';
import Papa from 'papaparse';
import { useStore } from '@/store';

export default defineComponent({
  setup() {
    const store = useStore();
    const cartStore = useCartStore();

    const headers = [
      {
        text: 'Code AMOS',
        value: 'codeAmos',
      },
      {
        text: 'Desc',
        value: 'data.description',
      },
      {
        text: 'Quantity',
        value: 'quantity',
        width: '50px',
      },
      {
        text: '',
        value: 'actions',
        align: 'end',
      },
    ];

    function onDownloadCSV() {
      const rows = cartStore.sortedItems.value.map((item) => {
        const row = {
          ...(item.data || {}),
          quantity: item.quantity,
        };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        delete (row as any)._id;
        return row;
      });

      const csv = Papa.unparse(rows, {
        quotes: true,
        header: true,
        delimiter: ';',
      });

      const blob = new Blob([csv], {
        type: 'text/csv',
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'rovjet568-parts.csv';
      document.body.appendChild(link);
      link.click();
      link.remove();
    }

    function onQuantityChange() {
      cartStore.persist();
    }

    function onClickItem(item: CartItem) {
      store.state.showSearchPart = item.codeAmos;
    }

    return {
      headers,
      items: cartStore.sortedItems,
      cartStore,
      onDownloadCSV,
      onQuantityChange,
      onClickItem,
    };
  },
});
