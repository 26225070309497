import { useStore } from '@/store';
import { User } from '@project/shared';

export function hasRoles(user: User, ...roles: string[]) {
  if (!roles || !roles.length) return true;
  if (!user) return false;
  if (!user.roles) return false;
  for (const role of roles) {
    if (user.roles.includes(role)) return true;
  }
  return false;
}

export function useHasRoles() {
  const { user } = useStore().state;
  return (...roles: string[]) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return hasRoles(user!, ...roles);
  };
}
