import { RouteConfig } from 'vue-router';

export const usersRoutes: RouteConfig[] = [
  {
    path: '/login',
    name: 'login',
    component: () => import('./views/LoginView.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('./views/ProfileView.vue'),
  },
];
