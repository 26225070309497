




























import { defineComponent, computed } from 'vue-demi';
import RequireOnline from './RequireOnline.vue';

export default defineComponent({
  components: {
    RequireOnline,
  },

  props: {
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    value: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const show = computed({
      get: () => props.value,
      set: (value: boolean) => emit('input', value),
    });

    function onOK() {
      emit('ok');
      show.value = false;
    }

    function onCancel() {
      emit('cancel');
      show.value = false;
    }

    return {
      show,
      onOK,
      onCancel,
    };
  },
});
