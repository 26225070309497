import { Dexie } from 'dexie';
import { Part as PartModel, Certificate, Schematic } from '@project/shared';

export interface Kv {
  id: string;
  value: unknown;
}

export interface File {
  id: string;
  timestamp: number;
  file: Blob;
}

export interface Part {
  codeAmos: string;
  data: PartModel;
}

class AppDb extends Dexie {
  kv: Dexie.Table<Kv, string>;
  files: Dexie.Table<File, string>;
  parts: Dexie.Table<Part, string>;
  certificates: Dexie.Table<Certificate, string>;
  schematics: Dexie.Table<Schematic, string>;

  constructor() {
    super('AppDb');

    this.version(5).stores({
      kv: '&id, value',
      files: '&id, file, timestamp',
      parts: '&codeAmos, data.modelMetadata.meshId, data.refManufacturer',
      certificates: '&codeAmos',
      certificates2: '&docRef',
      schematics: '&docRef',
    });

    this.kv = this.table('kv');
    this.files = this.table('files');
    this.parts = this.table('parts');
    this.certificates = this.table('certificates2');
    this.schematics = this.table('schematics');
  }
}

export const db = new AppDb();
