import { useHasRoles } from '@/composables/hashRoles';
import { defineComponent, PropType } from '@vue/composition-api';
import Vue from 'vue';

const HasRoles = defineComponent({
  props: {
    roles: {
      type: [Array, String] as PropType<string | string[]>,
      default: () => [],
    },
  },
  setup(props, ctx) {
    const hasRoles = useHasRoles();
    const roles = typeof props.roles === 'string' ? [props.roles] : props.roles;
    if (roles.length && !hasRoles(...roles)) {
      return () => null;
    }
    return () => ctx.slots && ctx.slots.default && ctx.slots.default();
  },
});

export default HasRoles;

Vue.component('HasRoles', HasRoles);
