/* eslint-disable no-console */

import { register } from 'register-service-worker';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log('[SW] Using cache');
    },
    registered() {
      console.log('[SW] Registered');
    },
    cached() {
      console.log('[SW] Content cached');
    },
    updatefound() {
      console.log('[SW] Updating...');
    },
    updated() {
      console.log('[SW] Need refresh');
      const updatePopup = document.querySelector<HTMLDivElement>('#update-popup');
      console.log(updatePopup);
      if (updatePopup) {
        updatePopup.style.display = 'block';
      } else {
        console.error('[SW] Cannot display update popup');
      }
    },
    offline() {
      console.log('[SW] Offline mode');
    },
    error(error) {
      console.error('[SW] Error', error);
    },
  });
}
