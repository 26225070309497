

















import { defineComponent } from 'vue-demi';
import { useOnline } from '@vueuse/core';

export default defineComponent({
  props: {
    hideLabel: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const isOnline = useOnline();
    return {
      isOnline,
    };
  },
});
