import { unparse } from 'papaparse';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import { db } from './AppDb';

const schematicsMapping = {
  codeAmos: 'CodeAMOS',
  type: 'Type',
  docRef: 'DocRef',
  title: 'Title',
  rev: 'Rev',
  documentation: 'PDF File',
};

const certificatesMapping = {
  codeAmos: 'CodeAMOS',
  description: 'Description',
  comment: 'Comment',
  doc: 'Doc',
};

const partsMapping = {
  codeAmos: 'CodeAMOS',
  description: 'Description',
  quantity: 'Quantite',
  family: 'Famille',
  subFamily: 'Ss-famille',
  refManufacturer: 'Ref_Fabricant',
  supplier: 'Fournisseur',
  documentation: 'Private Documentation',
  visible: 'Visible',
  ga: 'GA',
  subLevelGa: 'Sub_Level_GA',
  mainAssy: 'Main_Assy',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function convertRow(input: any, mapping: Record<string, string>) {
  const output: Record<string, string> = {};
  for (const key in mapping) {
    output[mapping[key]] = input[key] || '';
  }
  return output;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getCSV(data: any[], mapping: Record<string, string>) {
  return unparse(
    data.map((item) => convertRow(item, mapping)),
    {
      quotes: true,
      delimiter: ';',
      columns: undefined,
    },
  );
}

export async function exportDb() {
  const schematics = getCSV(await db.schematics.toArray(), schematicsMapping);
  const certificates = getCSV(await db.certificates.toArray(), certificatesMapping);

  const partsItems = (await db.parts.toArray())
    .map((item) => item.data)
    .reduce((items, item) => {
      for (const gaId in item.gaItems) {
        const quantity = item.gaItems[gaId];

        items.push({
          ...item,
          ga: gaId,
          quantity,
        });
      }
      return items;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }, [] as any[]);

  const parts = getCSV(partsItems, partsMapping);

  const zip = new JSZip();
  zip.file('schematics.csv', schematics);
  zip.file('certificates.csv', certificates);
  zip.file('drawings.csv', parts);
  FileSaver.saveAs(await zip.generateAsync({ type: 'blob' }), 'db.zip');
}
