






import { defineComponent, computed, ref } from 'vue-demi';
import { useRoute, useRouter } from '@/router';
import DefaultLayout from './DefaultLayout.vue';
import BlankLayout from './BlankLayout.vue';
import { VueConstructor } from 'vue';

export default defineComponent({
  setup() {
    const route = useRoute();
    const router = useRouter();

    // eslint-disable-next-line no-undef
    const currentRoute = ref(route.value);
    const layouts: Record<string, VueConstructor> = {
      default: DefaultLayout,
      blank: BlankLayout,
    };

    router.beforeResolve((to, _, next) => {
      currentRoute.value = to;
      next();
    });

    const layoutComponent = computed(() => {
      const layoutName = currentRoute.value?.meta?.layout || 'default';
      return layouts[layoutName] || DefaultLayout;
    });

    return {
      layoutComponent,
    };
  },
});
