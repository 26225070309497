import { inject, provide, ref } from 'vue-demi';
import { UserResponse, UsersListQuery } from '@project/shared';
import { useApi } from '@/api';

export function createUsersList() {
  const api = useApi();
  const loading = ref(false);
  const items = ref<UserResponse[]>([]);
  const total = ref(0);
  let currentQuery: UsersListQuery;

  async function getItems(query: UsersListQuery) {
    currentQuery = query;
    loading.value = true;
    try {
      const response = await api.users.list(query);
      items.value = response.items;
      total.value = response.total;
    } finally {
      loading.value = false;
    }
  }

  function refresh() {
    return getItems(currentQuery);
  }

  const usersList = {
    loading,
    items,
    total,
    getItems,
    refresh,
  };

  provide('usersList', usersList);
  return usersList;
}

export function useUsersList() {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return inject<ReturnType<typeof createUsersList>>('usersList')!;
}
