


















































import { defineComponent, computed, ref } from '@vue/composition-api';
import { useStore } from '@/store';
import UserEditForm from './UserEditForm.vue';
import UsersTable from './UsersTable.vue';
import { User } from '@project/shared';
import { createUsersList } from '../useUsersList';
import RequireOnline from '@/components/RequireOnline.vue';

export default defineComponent({
  components: { UsersTable, UserEditForm, RequireOnline },
  setup() {
    const store = useStore();
    const show = computed<boolean>({
      get: () => store.state.showUsersDialog || false,
      set: (value: boolean) => (store.state.showUsersDialog = value),
    });

    const { refresh } = createUsersList();

    const editItem = ref<User>();
    function onEditItem(item?: User) {
      editItem.value = item;
    }

    function onUpdateItem() {
      refresh();
      editItem.value = undefined;
    }

    return {
      show,
      onEditItem,
      editItem,
      onUpdateItem,
    };
  },
});
