import { AxiosInstance } from 'axios';
import { Schematic, SchematicSaveBody } from '@project/shared';
import { db } from '@/db';

export class Schematics {
  constructor(private readonly http: AxiosInstance) {}

  public async save(body: SchematicSaveBody) {
    const { data } = await this.http.post<Schematic>('/db/schematics', body);

    // Update local DB
    await db.schematics.put(body as Schematic);

    return data;
  }

  public async delete(docRef: string, codeAmos?: string) {
    await this.http.delete(`/db/schematics/${docRef}`, {
      params: { codeAmos },
    });

    if (codeAmos) {
      const item = await db.schematics.get(docRef);
      if (item) {
        const index = item?.codeAmos.findIndex((ref) => ref === codeAmos);
        if (index >= 0) {
          item.codeAmos.splice(index, 1);
        }
        await db.schematics.put(item);
      }
    } else {
      await db.schematics.delete(docRef);
    }
  }
}
