import { AxiosInstance } from 'axios';
import { Part, PartSaveBody } from '@project/shared';
import { db } from '@/db';

export class Parts {
  constructor(private readonly http: AxiosInstance) {}

  public async save(body: PartSaveBody) {
    const { data } = await this.http.post<Part>('/db/parts', body);

    // Update local DB
    await db.parts.put({
      codeAmos: data.codeAmos,
      data: data,
    });

    return data;
  }

  public async delete(codeAmos: string, ga: string) {
    return this.http.delete(`/db/parts/${codeAmos}/${ga}`);
  }

  public async upload(fileName: string, file: File, progress?: (progress: number) => void) {
    const formData = new FormData();
    formData.append('file', file);
    const { data } = await this.http.post<boolean>(`/db/files/${fileName}`, formData, {
      onUploadProgress(event: ProgressEvent) {
        if (progress) {
          progress(event.loaded / event.total);
        }
      },
    });

    // Update local DB
    await db.files.put({ file, id: fileName, timestamp: Date.now() });

    return data;
  }
}
