import { AxiosInstance } from 'axios';
import { LoginBody, ProfileUpdateBody, User } from '@project/shared';
import { RequestConfig } from './Client';
import { kv } from '../db/Kv';

export class Auth {
  constructor(private readonly http: AxiosInstance) {}

  public async login(body: LoginBody) {
    const { data } = await this.http.post<User>('/users/auth/login', body, {
      disableErrorCatcher: true,
    } as RequestConfig);

    await kv.set('auth.user', data);
    await kv.set('auth.timestamp', Date.now());

    return data;
  }

  public async me() {
    const { data } = await this.http.get<User>('/users/auth/me');
    await kv.set('auth.user', data);
    return data;
  }

  public async updateMe(body: ProfileUpdateBody) {
    const { data } = await this.http.post<User>('/users/auth/me', body);
    await kv.set('auth.user', data);
    return data;
  }

  public async logout() {
    const { data } = await this.http.post<boolean>('/users/auth/logout');

    await kv.remove('auth.user');
    await kv.remove('auth.timestamp');

    return data;
  }
}
