import { db, File } from '@/db/AppDb';
import { Certificate, Part, Schematic } from '@project/shared';
import { AxiosInstance } from 'axios';
import difference from 'lodash.difference';
import { kv } from '../db/Kv';

enum TaskType {
  Db = 'Db',
  Certificates = 'Certificates',
  Schematics = 'Schematics',
  File = 'File',
}

export interface ImportTask {
  type: TaskType;
  data?: unknown;
}

export interface ImportProgressEvent {
  task: number;
  done: number;
  total: number;
}

export class Downloader {
  constructor(private readonly http: AxiosInstance) {}

  public async getTimestamps(time?: number) {
    const { data } = await this.http.get<{
      lastImport: number;
      lastDelete: number;
      files: {
        [name: string]: {
          [file: string]: number;
        };
      };
      updatesCount: number;
    }>('/db/timestamps', {
      params: {
        time,
      },
    });
    return data;
  }

  public async getParts() {
    const { data } = await this.http.get<Part[]>('/db/parts');
    return data;
  }

  public async getCertificates() {
    const { data } = await this.http.get<Certificate[]>('/db/certificates');
    return data;
  }

  public async getSchematics() {
    const { data } = await this.http.get<Schematic[]>('/db/schematics');
    return data;
  }

  public async getUpdatesItems(fullImport = false) {
    const tasks: ImportTask[] = [];
    const timestamp = fullImport ? 0 : parseInt((await kv.get('import.timestamp')) || '');
    const { lastImport, lastDelete, files, updatesCount } = await this.getTimestamps(timestamp);

    if (!timestamp || lastImport > timestamp || lastDelete > timestamp || updatesCount > 0) {
      tasks.push({
        type: TaskType.Db,
      });
      tasks.push({
        type: TaskType.Certificates,
      });
      tasks.push({
        type: TaskType.Schematics,
      });
    }

    const dbFiles = await db.files.toArray();
    const remoteFiles: File[] = [];

    for (const type in files) {
      for (const name in files[type]) {
        remoteFiles.push({
          id: `${type}/${name}`,
          timestamp: parseInt(files[type][name].toString()),
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          file: null as any,
        });
      }
    }

    const dbIds = dbFiles.map((item) => item.id);
    const remoteIds = remoteFiles.map((item) => item.id);
    const deleteIds = difference(dbIds, remoteIds);

    if (deleteIds.length) {
      await db.files.bulkDelete(deleteIds);
    }

    let downloadFiles = [...remoteFiles];
    if (timestamp) {
      downloadFiles = downloadFiles.filter((item) => {
        if (!dbIds.includes(item.id)) return true;
        return item.timestamp > timestamp;
      });
    }

    if (remoteFiles.length) {
      tasks.push(
        ...downloadFiles.map((item) => {
          return {
            type: TaskType.File,
            data: item,
          };
        }),
      );
    }

    return tasks;
  }

  public async download(
    tasks: ImportTask[],
    onProgress?: (event: ImportProgressEvent) => void,
  ): Promise<Error[]> {
    const now = Date.now();
    const errors: Error[] = [];
    const total = tasks.length;
    let done = 0;

    function triggerProgress(task = -1) {
      if (onProgress) {
        onProgress({
          done,
          total,
          task,
        });
      }
    }

    for (const task of tasks) {
      try {
        if (task.type === TaskType.Db) {
          // DB DOWNLOAD
          const items = await this.getParts();
          triggerProgress(0.5);
          await db.parts.clear();
          await db.parts.bulkPut(
            items.map((item) => {
              return {
                codeAmos: item.codeAmos,
                data: item,
              };
            }),
          );
          triggerProgress(1);
          await kv.set('db.available', true);
        } else if (task.type === TaskType.Certificates) {
          // DB DOWNLOAD
          const items = await this.getCertificates();
          triggerProgress(0.5);
          await db.certificates.clear();
          await db.certificates.bulkPut(items);
          triggerProgress(1);
          await kv.set('db.available', true);
        } else if (task.type === TaskType.Schematics) {
          // DB DOWNLOAD
          const items = await this.getSchematics();
          triggerProgress(0.5);
          await db.schematics.clear();
          await db.schematics.bulkPut(items);
          triggerProgress(1);
          await kv.set('db.available', true);
        } else if (task.type === TaskType.File) {
          // FILE DOWNLOAD
          const item = task.data as File;
          const fileUrl = `/db/files/${item.id}`;
          const { data } = await this.http.get<Blob>(fileUrl, {
            responseType: 'blob',
            onDownloadProgress(event) {
              if (onProgress) {
                onProgress({
                  done,
                  total,
                  task: event.loaded / event.total,
                });
              }
            },
          });

          item.file = data;

          await db.files.put(item);
        }
      } catch (err) {
        errors.push(err);
      } finally {
        done++;
        triggerProgress();
      }
    }

    await kv.set('import.timestamp', now);

    return errors;
  }
}
