import { AxiosInstance } from 'axios';
import {
  UserCreateBody,
  UserResponse,
  UsersListQuery,
  UsersListReponse,
  UserUpdateBody,
} from '@project/shared';

export class Users {
  constructor(private readonly http: AxiosInstance) {}

  public async list(params: UsersListQuery) {
    const { data } = await this.http.get<UsersListReponse>('/users', { params });
    return data;
  }

  public async create(body: UserCreateBody) {
    const { data } = await this.http.post<UserResponse>('/users', body);
    return data;
  }

  public async update(id: string, body: UserUpdateBody) {
    const { data } = await this.http.put<UserResponse>(`/users/${id}`, body);
    return data;
  }

  public async delete(id: string) {
    const { data } = await this.http.delete<UserResponse>(`/users/${id}`);
    return data;
  }
}
