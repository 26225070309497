import { db } from './AppDb';

export class Kv {
  public async get<T = unknown>(name: string) {
    const result = await db.kv.get(name);
    if (result) return result.value as T;
  }

  public async set(name: string, value: unknown) {
    await db.kv.put({ id: name, value }, name);
  }

  public async remove(name: string) {
    await db.kv.delete(name);
  }
}

export const kv = new Kv();
