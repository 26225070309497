import { AxiosInstance } from 'axios';
import { Certificate, CertificateSaveBody } from '@project/shared';
import { db } from '@/db';

export class Certificates {
  constructor(private readonly http: AxiosInstance) {}

  public async save(body: CertificateSaveBody) {
    const { data } = await this.http.post<Certificate>('/db/certificates', body);

    // Update local DB
    await db.certificates.put({
      ...data,
    });

    return data;
  }

  public async delete(codeAmos: string) {
    const item = await db.certificates.get(codeAmos);

    await this.http.delete(`/db/certificates/${codeAmos}`);

    await db.parts.delete(codeAmos);
    if (item?.doc) {
      await db.files.delete(`pdf/${item?.doc}`);
    }
  }
}
