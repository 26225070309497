







































import { defineComponent, ref, reactive, watch, computed } from 'vue-demi';
import { DataTableHeader } from 'vuetify';
import { UserResponse, UsersListQuery } from '@project/shared';
import { useApi } from '@/api';
import { debouncedWatch } from '@vueuse/core';
import UserEditForm from './UserEditForm.vue';
import { useUsersList } from '../useUsersList';
import Confirm from '@/components/Confirm.vue';

export default defineComponent({
  components: { UserEditForm, Confirm },

  setup(props, { emit }) {
    const api = useApi();
    const headers: DataTableHeader[] = [
      { text: 'Email', value: 'email', sortable: false },
      { text: 'Actions', value: 'actions', sortable: false, align: 'end' },
    ];
    const query = reactive<UsersListQuery>({ page: 1, pageSize: 50, search: '' });
    const searchValue = ref('');

    const { items, total, loading, getItems } = useUsersList();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const totalPage = computed(() => Math.ceil(total.value / query.pageSize!));

    debouncedWatch(
      searchValue,
      () => {
        query.search = searchValue.value;
        query.page = 1;
      },
      { debounce: 400 },
    );

    watch(
      () => query,
      () => {
        getItems(query);
      },
      {
        immediate: true,
        deep: true,
      },
    );

    function onEditItem(item?: UserResponse) {
      emit('editItem', item);
    }

    const deleteData = reactive<{
      show: boolean;
      index: number;
      item: UserResponse | null;
    }>({
      show: false,
      index: -1,
      item: null,
    });

    async function onDeleteItem(item: UserResponse, index: number) {
      deleteData.index = index;
      deleteData.item = item;
      deleteData.show = true;
    }

    async function onDeleteItemConfirm() {
      if (deleteData.item) {
        loading.value = true;
        try {
          await api.users.delete(deleteData.item._id);
          items.value.splice(deleteData.index, 1);
        } finally {
          loading.value = false;
        }

        deleteData.item = null;
        deleteData.index = -1;
      }
    }

    return {
      headers,
      getItems,
      items,
      query,
      loading,
      total,
      totalPage,
      searchValue,
      onEditItem,
      deleteData,
      onDeleteItemConfirm,
      onDeleteItem,
    };
  },
});
