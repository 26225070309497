import Vue from 'vue';
import { computed } from 'vue-demi';
import VueRouter, { RouteConfig } from 'vue-router';
import { dbRoutes } from './db';
import { helpRoutes } from './help';
import { tocRoutes } from './toc/index';
import { usersRoutes } from './users/routes';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  ...usersRoutes,
  ...dbRoutes,
  ...tocRoutes,
  ...helpRoutes,
  {
    path: '/offline',
    name: 'offline',
    component: () => import('./views/OfflineErrorView.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    component: () => import('./views/NotFoundView.vue'),
    meta: {
      layout: 'blank',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

const route = computed(() => {
  return router.currentRoute;
});

export function useRouter() {
  return router;
}

export function useRoute() {
  return route;
}

export default router;
