import { api } from '@/api';
import { Part, PartSaveBody } from '@project/shared';
import { computed, inject, provide, reactive } from 'vue-demi';
import { db } from './AppDb';
import { partsDb, PartsFilters } from './PartsDb';

interface PartsState {
  updates: number;
  items: Part[];
}

export function createPartsStore() {
  const state = reactive<PartsState>({
    updates: 0,
    items: [],
  });

  const updatesCount = computed(() => state.updates);
  const seItems = computed(() => {
    if (state.items && state.items.length) {
      return state.items
        .filter((item) => item.modelMetadata && item.modelMetadata.title)
        .sort((a, b) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          return a.modelMetadata!.title!.localeCompare(b.modelMetadata!.title!);
        });
    }
    return [];
  });

  const store = {
    state,

    updatesCount,
    seItems,

    async load() {
      state.items = await partsDb.load();
      return state.items;
    },

    search(search = '', filters: PartsFilters = {}) {
      return partsDb.search(search, filters);
    },

    getByMeshId(id: string) {
      return partsDb.items.find((item) => item.modelMetadata?.meshId === id);
    },

    getByCodeAmos(codeAmos: string) {
      return partsDb.items.find((item) => item.codeAmos === codeAmos);
    },

    async save(body: PartSaveBody) {
      const part = await api.parts.save(body);
      const index = partsDb.items.findIndex((item) => item.codeAmos === part.codeAmos);
      if (index >= 0) {
        partsDb.items.splice(index, 1, part);
      } else {
        partsDb.items.push(part);
      }

      // Manage children
      if (body.subLevelGa && body.children && body.children.length > 0) {
        for (const childAmos of body.children) {
          const child = await this.getByCodeAmos(childAmos);
          if (child) {
            if (!child.gaItems) child.gaItems = {};
            child.gaItems[body.subLevelGa] = 1;
            await this.save(child);
          }
        }
      }

      return part;
    },

    async delete(codeAmos: string, ga: string) {
      await api.parts.delete(codeAmos, ga);

      const part = await this.getByCodeAmos(codeAmos);
      if (part && part.gaItems) {
        delete part.gaItems[ga];
        const fullDelete = Object.keys(part.gaItems).length === 0;

        if (fullDelete) {
          await db.parts.delete(codeAmos);
        } else {
          await db.parts.put({
            codeAmos,
            data: part,
          });
        }

        partsDb.items.splice(
          partsDb.items.findIndex((part) => part.codeAmos === codeAmos),
          1,
          ...(fullDelete ? [] : [part]),
        );

        state.items.splice(
          state.items.findIndex((part) => part.codeAmos === codeAmos),
          1,
          ...(fullDelete ? [] : [part]),
        );
      }
    },

    async getUpdates() {
      const tasks = await api.downloader.getUpdatesItems();
      state.updates = tasks.length;
      return tasks;
    },
  };

  store.load().catch(console.error);

  provide('partsStore', store);

  return store;
}

export type PartsStore = ReturnType<typeof createPartsStore>;

export function usePartsStore() {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return inject<PartsStore>('partsStore')!;
}
