import { RouteConfig } from 'vue-router';

export * from './AppDb';
export * from './exportDb';

export const dbRoutes: RouteConfig[] = [
  {
    path: '/downloader',
    name: 'downloader',
    component: () => import('./views/DownloaderView.vue'),
  },
  {
    path: '/',
    name: 'home',
    component: () => import('./views/StartView.vue'),
  },
  {
    path: '/parts/:codeAmos',
    name: 'parts',
    component: () => import('./views/PartsView.vue'),
  },
  {
    path: '/certificates',
    name: 'certificates',
    component: () => import('./views/CertificatesView.vue'),
  },
  {
    path: '/schematics',
    name: 'schematics',
    component: () => import('./views/SchematicsView.vue'),
  },
];
