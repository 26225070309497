import { app } from '@/main';
import { AxiosError } from 'axios';

const errorsStack: Array<Error | AxiosError> = [];

let lastLoginError = 0;

export function errorCatcher(err: Error | AxiosError) {
  errorsStack.push(err);
  const toast = app.$toast;

  const axiosError = err as AxiosError;
  if (axiosError.response) {
    // HTTP call errors
    switch (axiosError.response.status) {
      case 401:
        // Not logged
        if (Date.now() - lastLoginError > 10000) {
          toast.error('You are not logged');
          lastLoginError = Date.now();
        }
        break;
      default:
        toast.error('Something went wrong');
        break;
    }
  } else {
    // Classic errors
    // TODO
  }
}
