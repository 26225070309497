












































import { defineComponent, computed, ref } from '@vue/composition-api';
import { useOnline } from '@vueuse/core';
import { useHasRoles } from '@/composables/hashRoles';
import { useRouter } from '@/router';
import { RawLocation } from 'vue-router';
import { useApi } from '@/api';
import { useStore } from '@/store';
import UpdatesStatus from '@/db/components/UpdatesStatus.vue';
import Cart from '@/db/components/Cart.vue';
import SeSelector from '@/db/components/SeSelector.vue';
import Confirm from '@/components/Confirm.vue';
import { db, exportDb } from '@/db';

interface MenuItem {
  title: string;
  icon: string;
  roles?: string[];
  route?: RawLocation;
  action?: Function;
}

export default defineComponent({
  components: { UpdatesStatus, Cart, SeSelector, Confirm },
  setup() {
    const isOnline = useOnline();
    const router = useRouter();
    const hasRoles = useHasRoles();
    const api = useApi();
    const store = useStore();
    const showLogoutConfirm = ref(false);

    function toggleLogoutConfirm() {
      showLogoutConfirm.value = !showLogoutConfirm.value;
    }

    async function onConfirmLogout() {
      // TODO: clean db
      await db.delete();
      await db.open();
      await api.auth.logout();
      store.state.user = undefined;
      router.replace({
        name: 'login',
        query: {
          from: router.currentRoute.fullPath,
        },
      });
    }

    function onCancelLogout() {
      toggleLogoutConfirm();
    }

    const menuItems = [
      {
        title: 'My profile',
        icon: 'mdi-account',
        action() {
          router.push({ name: 'profile' });
        },
      },
      {
        title: 'Manage users',
        icon: 'mdi-account-group',
        roles: ['admin'],
        action() {
          store.state.showUsersDialog = true;
        },
      },
      {
        title: 'Help',
        icon: 'mdi-help-circle',
        action() {
          router.push({ name: 'help' });
        },
      },
      {
        title: 'Export database',
        icon: 'mdi-database-export',
        roles: ['admin'],
        action() {
          exportDb();
        },
      },
      {
        title: 'Logout',
        icon: 'mdi-power-standby',
        async action() {
          toggleLogoutConfirm();
        },
      },
    ];

    const filteredMenuItems = computed(() => {
      return menuItems.filter((item) => hasRoles(...(item.roles || [])));
    });

    function onClickMenuItem(item: MenuItem) {
      if (item.route) {
        router.push(item.route);
      } else if (item.action) {
        item.action();
      }
    }

    const demoTitle = process.env.VUE_APP_TAG === 'master' ? '(Demo)' : '';

    return {
      isOnline,
      items: filteredMenuItems,
      onClickMenuItem,

      showLogoutConfirm,
      onConfirmLogout,
      onCancelLogout,

      demoTitle,
    };
  },
});
