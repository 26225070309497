






















































import { defineComponent, computed, watch, ref, onBeforeUnmount } from '@vue/composition-api';
import { useStore } from '@/store';
import { Part } from '@project/shared';
import { debouncedWatch } from '@vueuse/core';
import { usePartsStore } from '../partsStore';

export default defineComponent({
  setup() {
    const store = useStore();
    const parts = usePartsStore();
    const show = computed({
      get: () => store.state.showSearchPart || false,
      set: (value: boolean | string) => {
        if (value === true && typeof store.state.showSearchPart === 'string') {
          return;
        }
        store.state.showSearchPart = value;
      },
    });

    const search = ref('');
    const selected = ref<Part>();

    const items = ref<unknown[]>([]);

    function compareItems(a: Part, b: Part) {
      return a.codeAmos === b.codeAmos;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let stopWatch: any;
    function startSearchWatch() {
      if (stopWatch) stopWatch();
      stopWatch = debouncedWatch(
        search,
        async (value) => {
          if (value === null) return;
          items.value = (await parts.search(value, { noTop: true })).map((item) => ({
            text: item.codeAmos + ' | ' + (item.description || ''),
            value: item,
          }));
        },
        { debounce: 500 },
      );
    }

    onBeforeUnmount(() => {
      if (stopWatch) stopWatch();
    });

    watch(
      () => store.state.showSearchPart,
      async (value) => {
        search.value = '';
        if (typeof value === 'boolean') {
          selected.value = undefined;
        } else {
          if (value) selected.value = await parts.getByCodeAmos(value);
        }
        startSearchWatch();
      },
    );

    const assemblies = computed(() => {
      if (!selected.value) return [];
      const refs = Object.keys(selected.value.gaItems || {});
      if (!refs.length) return [];

      const results = refs.map((ref) => {
        return (
          parts.state.items.find((item) => item.top === true && item.ga === ref) ||
          parts.state.items.find((item) => item.subLevelGa === ref)
        );
      });

      return results;
    });

    function onClickItem() {
      show.value = false;
    }

    const label = computed(() => {
      if (selected.value) return selected.value.codeAmos;
      if (typeof store.state.showSearchPart === 'string') return store.state.showSearchPart;
      return '';
    });

    return {
      show,
      store,

      compareItems,
      search,
      items,
      selected,
      assemblies,
      label,

      onClickItem,
    };
  },
});
